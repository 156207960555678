import { Button, Col, Menu, MenuItemProps, Row, Select, Space } from 'antd';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { useObj } from '@shared/hooks/useObj/useObj';
import { all_ages } from '@shared/models/Age';
import { FacilityMap, MapCamera } from 'pages/facility/top/TopPage/FacilityMap';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useCallback, useEffect, useState } from 'react';
import { AccountSettingSetting } from 'pages/urban/top/TopPage/AccountSetting';
import { Map } from 'api/maps';
import { hasHumanTraffic } from '@shared/models/Content';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { AgeSettingEngine } from 'components/AgeSettingEngine';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { AgeGroups, camera_person_data_record, generatePersonData, sumAgeGroups } from 'api/countData';
import { CaretRightOutlined, SettingOutlined, GlobalOutlined } from '@ant-design/icons';
import history from '@shared/browserHistory';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

export default function TodosTopPage() {
  const { obj: content_setting, updateObj: updateContentSetting } = useObj<ContentSetting>({
    contents: [],
  });
  const { obj: age_setting, updateObj: updateAgeSetting } = useObj<AgeSetting>({
    ages: all_ages,
  });
  const { obj: period_setting, updateObj: updatePeriodSetting } = useObj<PeriodSetting>({
    unit: 'hour',
    period: 'today',
  });

  const { cameras } = useCameras(5);
  const { maps, maps_record } = useMaps();
  const [map, setMap] = useState<Map | undefined>();
  const { maps: facility_maps, maps_record: facility_maps_record, loadMaps: loadFacilityMaps } = useMaps(map?.map_id);
  const [selected_facility_map, setSelectedFacilityMap] = useState<Map>();
  const [map_cameras, setMapCameras] = useState<MapCamera[] | undefined>(undefined);

  const loadTraffic = useCallback(
    (args: { display_content_setting?: ContentSetting; age_setting?: AgeSetting; period_setting?: PeriodSetting }) => {
      const { display_content_setting, age_setting, period_setting } = args;
      const use_human_traffic = display_content_setting?.contents.includes('humanTraffic');
      const use_gender = display_content_setting?.contents.includes('gender');
      const new_map_cameras = cameras?.map((camera) => {
        const result: MapCamera = { ...camera };
        // [TODO] ダミーデータをやめる
        let person_data = camera_person_data_record[camera.camera_id];
        if (period_setting) {
          person_data = generatePersonData();
        }
        if (use_human_traffic) {
          const partial_man: Partial<AgeGroups> = {};
          const partial_woman: Partial<AgeGroups> = {};
          age_setting?.ages.forEach((age) => {
            partial_man[age] = person_data.man[age];
            partial_woman[age] = person_data.woman[age];
          });
          const total = sumAgeGroups(partial_man) + sumAgeGroups(partial_woman);
          result.human_traffic = {
            total,
          };
        }
        if (use_gender) {
          const man_total = sumAgeGroups(person_data.man);
          const woman_total = sumAgeGroups(person_data.woman);
          result.gender = {
            men: man_total,
            women: woman_total,
          };
        }
        return result;
      });
      setMapCameras(new_map_cameras);
    },
    [cameras],
  );

  const customUpdatePeriodSetting = (form: PeriodSetting) => {
    updatePeriodSetting(form);
    loadTraffic({ display_content_setting: content_setting, age_setting, period_setting: form });
  };

  const customUpdateContentSetting = (form: ContentSetting) => {
    const { contents } = form;
    if (hasHumanTraffic(contents)) {
      updateAgeSetting({
        ages: all_ages,
      });
    }
    updateContentSetting(form);
    loadTraffic({ display_content_setting: form, age_setting });
  };

  const onAgeSettingModalFinish = (form: AgeSetting) => {
    updateAgeSetting(form);
    loadTraffic({ display_content_setting: content_setting, age_setting: form });
  };

  const onMapChange = (new_map_id: string) => {
    setMap(maps_record[new_map_id]);
    loadFacilityMaps(new_map_id);
    // loadAll(base_request);
  };

  const onFacilityChange: MenuItemProps['onClick'] = (new_facility) => {
    setSelectedFacilityMap(facility_maps_record[new_facility.key]);
  };

  // UrbanViewへの遷移
  const onUrbanview = () => {
    history.push(`/urban/top`);
  };

  // 施設編集への遷移
  const onFacilityEdit = () => {
    // history.push(`/facility/top/edit/?map_id=${map?.map_id}`);
    history.push(`/facility/top/edit`);
  };

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const map_items: MenuItem[] =
    facility_maps?.map((map) => getItem(map.facility_name, map.map_id, <CaretRightOutlined />)) || [];

  useEffect(() => {
    loadTraffic({});
  }, [loadTraffic]);

  useEffect(() => {
    setMap(maps?.[0]);
    // loadFacilityMaps(maps?.[0]?.map_id);
  }, [
    maps,
    // loadFacilityMaps
  ]);

  useEffect(() => {
    setSelectedFacilityMap(facility_maps?.[0]);
  }, [facility_maps]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: 60, backgroundColor: '#000' }}>
        <Space style={{ width: '100%', height: '100%' }} direction='vertical'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
            <Space size={8} style={{ zIndex: 2 }}>
              <PeriodSettingEngine
                form={period_setting}
                updateForm={customUpdatePeriodSetting}
                input_style={{ width: 250 }}
              />
              <ContentSettingEngine updateForm={customUpdateContentSetting} form={content_setting} />
              {hasHumanTraffic(content_setting.contents) && (
                <AgeSettingEngine updateForm={onAgeSettingModalFinish} form={age_setting} />
              )}
            </Space>
            <Space style={{ zIndex: 2 }}>
              <Col span={24}>
                <Button onClick={onUrbanview} type='primary' shape='round'>
                  <GlobalOutlined /> UrbanView
                </Button>
              </Col>
              <AccountSettingSetting />
            </Space>
          </div>
        </Space>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 60px)' }}>
        <div style={{ width: '200px', borderRight: '1px solid #808080', padding: '10px', textAlign: 'left' }}>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Select
                style={{ width: '100%' }}
                value={map?.map_id ?? ''}
                onChange={onMapChange}
                options={maps?.map((map) => {
                  return { value: map.map_id, label: map.map_name };
                })}
              />
            </Col>
            <Col span={24}>
              <Menu
                selectedKeys={[selected_facility_map?.map_id ?? '']}
                items={map_items}
                onClick={(item) => onFacilityChange(item)}
              />
              <hr style={{ backgroundColor: '#CCC', height: '1px', border: 'none' }} />
            </Col>
            <Col span={24}>
              <Button onClick={onFacilityEdit} type='primary' shape='round'>
                <SettingOutlined /> 施設の登録・編集
              </Button>
            </Col>
          </Row>
        </div>
        {selected_facility_map && (
          <FacilityMap
            map={selected_facility_map}
            map_cameras={map_cameras}
            detail_props={{
              period_setting: period_setting,
            }}
          />
        )}
      </div>
    </div>
  );
}
