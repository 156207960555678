import { SelectItem } from './SelectItem';

export type Content = 'humanTraffic' | 'gender';

export const hasHumanTraffic = (contents: Content[]) => {
  return contents.includes('humanTraffic');
};

export const contentToJP = (content: Content) => {
  switch (content) {
    case 'humanTraffic':
      return '人流';
    case 'gender':
      return '性別';
    default:
      return '';
  }
};

export const content_select_items: SelectItem<string>[] = [
  { value: 'humanTraffic', label: contentToJP('humanTraffic') },
  { value: 'gender', label: contentToJP('gender') },
];
