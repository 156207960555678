/*** エンティティ ***/

import { sendAxios } from '@shared/api/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601, toISO8601 } from '@shared/models/ISO8601';
import getClient from './base';
import { Content } from '@shared/models/Content';
import { Status } from '@shared/models/Status';
import { Age } from '@shared/models/Age';
import { ViewType } from '@shared/models/ViewType';
import { Unit } from '@shared/models/Unit';
import { Period } from '@shared/models/Period';
import { Role } from '@shared/models/Role';
import { addFormData } from '@shared/functions';
import { Color } from 'antd/es/color-picker';

export interface User {
  user_id: string;
  login_id: string;
  mail_address: string;
  home_page: ViewType;
  home_address: string;
  display_unit: Unit;
  display_period: Period;
  start_day: number | null;
  start_time: number | null;
  start_month: number | null;
  start_date: string;
  is_display_content: boolean;
  display_contents: Content[];
  display_ages: Age[];
  circle_color: string | Color;
  role: Role;
  status: Status;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export const sample_user1: User = {
  user_id: '00000001',
  login_id: 'johndoe123',
  mail_address: 'johndoe@example.com',
  home_page: 'urban',
  home_address: '123 Main Street',
  display_unit: 'hour',
  display_period: 'period',
  start_day: 1,
  start_time: 8,
  start_month: 2,
  start_date: 'sun',
  is_display_content: true,
  display_contents: [],
  display_ages: ['child'],
  circle_color: '#FFA500',
  role: 'general',
  status: 'active',
  created_at: toISO8601(new Date()),
  updated_at: toISO8601(new Date()),
  deleted_at: null,
};

/*** [GET] /users/{user_id} ***/
export interface RequestUsersIdGet extends CommonAPIRequestType {}

export const usersIdGetAPI = () => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `users`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    mock_response: sample_user1,
  });
};

/*** [PUT] /users/{user_id} ***/
export interface RequestUsersIdPut extends CommonAPIRequestType {
  user_id: User['user_id'];
  home_page: User['home_page'];
  display_unit: User['display_unit'];
  display_period: User['display_period'];
  start_day?: User['start_day'];
  start_time?: User['start_time'];
  start_date?: User['start_date'];
  start_month?: User['start_month'];
  display_content: User['display_contents'];
  display_age: User['display_ages'];
  circle_color: User['circle_color'];
}

export const usersIdPutAPI = ({ user_id, ...remain }: RequestUsersIdPut) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `users/${user_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(remain)) {
    addFormData({ form, key, value });
  }

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    mock_response: sample_user1,
  });
};

/*** [POST] /auth ***/
export interface RequestAuthPost {
  login_id: string;
  password: string;
}

export const authPostAPI = (args: RequestAuthPost) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `auth`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(args)) {
    addFormData({ form, key, value });
  }

  // 送信
  return sendAxios<User>({
    axios,
    path,
    query,
    form,
    method,
    mock_response: sample_user1,
  });
};
