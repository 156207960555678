import { AuthProvider } from './AuthProvider';

export interface DgDocumentProps {
  children?: React.ReactNode;
}

export function DgDocument({ children }: DgDocumentProps) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ height: '100%', width: '100%', padding: '0px', overflow: 'hidden', position: 'relative' }}>
        <AuthProvider />
        {children}
      </div>
    </div>
  );
}
