import { Select, SelectProps } from 'antd';

const select_times = [
  { value: '0-1', label: '0:00~1:00' },
  { value: '1-2', label: '1:00~2:00' },
  { value: '2-3', label: '2:00~3:00' },
  { value: '3-4', label: '3:00~4:00' },
  { value: '4-5', label: '4:00~5:00' },
  { value: '5-6', label: '5:00~6:00' },
  { value: '6-7', label: '6:00~7:00' },
  { value: '7-8', label: '7:00~8:00' },
  { value: '8-9', label: '8:00~9:00' },
  { value: '9-10', label: '9:00~10:00' },
  { value: '10-11', label: '10:00~11:00' },
  { value: '11-12', label: '11:00~12:00' },
  { value: '12-13', label: '12:00~13:00' },
  { value: '13-14', label: '13:00~14:00' },
  { value: '14-15', label: '14:00~15:00' },
  { value: '15-16', label: '15:00~16:00' },
  { value: '16-17', label: '16:00~17:00' },
  { value: '17-18', label: '17:00~18:00' },
  { value: '18-19', label: '18:00~19:00' },
  { value: '19-20', label: '19:00~20:00' },
  { value: '20-21', label: '20:00~21:00' },
  { value: '21-22', label: '21:00~22:00' },
  { value: '22-23', label: '22:00~23:00' },
  { value: '23-24', label: '23:00~24:00' },
];

export interface HourTimeSelectProps extends Partial<SelectProps> {
  updateTimes(times: [number, number]): void;
}
export function HourTimeSelect(props: HourTimeSelectProps) {
  const { value, updateTimes, ...remain } = props;
  const times_str = value?.join('-');
  const onTimesSelect = (value: string) => {
    const str_times = value.split('-');
    if (str_times.length !== 2) return;
    updateTimes([Number(str_times[0]), Number(str_times[1])]);
  };
  return <Select {...remain} value={times_str} onChange={onTimesSelect} options={select_times} />;
}
