import Input from 'antd/es/input';
import Popover from 'antd/es/popover';
import Space from 'antd/es/space';
import { AgeSetting, AgeSelect } from 'components/AgeSettingEngine/AgeSelect';
import { toAgeSettingValue } from '@shared/functions/index';
import { useMemo } from 'react';

export interface AgeSettingEngineProps {
  updateForm(form: AgeSetting): void;
  form: AgeSetting;
}
export function AgeSettingEngine(props: AgeSettingEngineProps) {
  const { updateForm, form } = props;
  const age_setting_value = useMemo(() => {
    return toAgeSettingValue(form.ages);
  }, [form]);
  return (
    <Popover
      content={
        <Space size={[0, 16]} direction='vertical' style={{ width: 300 }}>
          <AgeSelect form={form} updateForm={updateForm} />
        </Space>
      }
      trigger='click'
    >
      <Input placeholder='クリックして値を選択' value={age_setting_value} readOnly style={{ width: 250 }} />
    </Popover>
  );
}
