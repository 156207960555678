import { Age } from '@shared/models/Age';
import { Unit } from '@shared/models/Unit';

export type TimeMetric = {
  time: number;
  value: number;
};
export type KeyMetric = {
  key: string;
  name: string;
  value: number;
};

export interface countDataGetRequest {
  camera_id: string;
  start: number;
  end: number;
  unit: Unit;
  direction: string;
  data_type: 'gender' | 'age' | 'total';
}

export interface AgeGroups {
  preschooler: number;
  child: number;
  youth: number;
  middleAged: number;
  elderly: number;
}

export interface PersonData {
  time: number;
  man: AgeGroups;
  woman: AgeGroups;
}

export function generatePersonData(time?: number): PersonData {
  const randomAge = () => Math.floor(Math.random() * 100) + 10; // 100から1000までのランダムな数
  const randomTime = () => time ?? Math.floor(Math.random() * (1709459200 - 1609459200) + 1609459200); // 2023年までのランダムなUNIXタイムスタンプ

  const data: PersonData = {
    time: randomTime(),
    man: {
      preschooler: randomAge(),
      child: randomAge(),
      youth: randomAge(),
      middleAged: randomAge(),
      elderly: randomAge(),
    },
    woman: {
      preschooler: randomAge(),
      child: randomAge(),
      youth: randomAge(),
      middleAged: randomAge(),
      elderly: randomAge(),
    },
  };

  return data;
}

export const camera_person_data_record: Record<string, PersonData> = {};

for (let i = 1; i <= 30; i++) {
  const cameraId = i.toString().padStart(8, '0');
  camera_person_data_record[cameraId] = generatePersonData();
}

export function generatePersonDataList(num: number): PersonData[] {
  const data_list: PersonData[] = [];
  for (let i = 0; i < num; i++) {
    data_list.push(generatePersonData());
  }
  return data_list;
}

export const sumAgeGroups = (data: Partial<AgeGroups>) => {
  let sum = 0;
  for (const value of Object.values(data)) {
    sum += value;
  }
  return sum;
};

/**
 * man | womanによりPersonData[]を足し算する関数
 */
export const sumPersonDataListByGender = (sum_key: 'man' | 'woman', data?: PersonData[]) => {
  return (
    data?.reduce(function (_sum, element) {
      return _sum + sumAgeGroups(element[sum_key]);
    }, 0) ?? 0
  );
};

/**
 * AgeGroupsによりPersonData[]を足し算する関数
 */
export const sumPersonDataListByAgeGroups = (sum_key: Age, data?: PersonData[]) => {
  return (
    data?.reduce(function (_sum, element) {
      return _sum + element.man[sum_key] + element.woman[sum_key];
    }, 0) ?? 0
  );
};
