import { List, Space, Spin } from 'antd';
import { Todo, todosGetAPI } from 'api/todos';
import { useCallback, useEffect, useState } from 'react';

export default function TestPage() {
  const [todos, setTodos] = useState<Todo[] | undefined>(undefined);
  const loadTodos = useCallback(async () => {
    const res = await todosGetAPI();
    console.log({ res });
    if (res.status === 200) {
      setTodos(res.data);
    }
  }, []);
  useEffect(() => {
    loadTodos();
  }, [loadTodos]);
  return (
    <Space style={{ width: '100%' }} direction='vertical'>
      {todos ? (
        <List
          itemLayout='vertical'
          dataSource={todos}
          renderItem={(todos) => (
            <List.Item>
              <List.Item.Meta title={todos.title} description={`task: ${todos.done}`} />
            </List.Item>
          )}
        />
      ) : (
        <Spin />
      )}
    </Space>
  );
}
