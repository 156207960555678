import { AxiosInstance, AxiosResponse } from 'axios';
import { Query, toAxiosQuery } from '../models/Query';
import toMock from './toMock';

export function sendAxios<ResponseType>(args: {
  axios: AxiosInstance;
  path: string;
  query: Query;
  form: any;
  method: string;
  mock_response?: ResponseType;
}): Promise<AxiosResponse<ResponseType, any>> {
  const { axios, path, query, form, method, mock_response } = args;
  const axios_query = toAxiosQuery(query);
  let all_path = `http://localhost:8000/${path}`;
  // yarn buildした時以外はモックを使用
  // if (process.env.NODE_ENV !== 'production') {
  // mock_responseを指定している場合はその値を返却する
  if (mock_response) {
    all_path = path;
    toMock(axios, path, axios_query, method, mock_response);
  }
  // }

  if (method === 'post') {
    return axios.post(all_path, form).then((res: AxiosResponse<ResponseType>) => res);
  } else if (method === 'put') {
    return axios.put(all_path, form).then((res: AxiosResponse<ResponseType>) => res);
  } else if (method === 'delete') {
    return axios.delete(all_path, axios_query).then((res: AxiosResponse<ResponseType>) => res);
  }
  return axios.get(all_path, axios_query).then((res: AxiosResponse<ResponseType>) => res);
}
