import { Space } from 'antd';
import { SearchEngine } from './SearchEngine';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { useObj } from '@shared/hooks/useObj/useObj';
import { all_ages } from '@shared/models/Age';
import { BaseMap, MapCamera } from 'pages/urban/top/TopPage/BaseMap';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AccountSettingSetting } from 'pages/urban/top/TopPage/AccountSetting';
import { Camera } from 'api/cameras';
import { hasHumanTraffic } from '@shared/models/Content';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { AgeSettingEngine } from 'components/AgeSettingEngine';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { AgeGroups, camera_person_data_record, generatePersonData, sumAgeGroups } from 'api/countData';

const isSearchedCamera = (searched_camera: Camera) => (camera: Camera) => {
  return camera.camera_id === searched_camera.camera_id;
};

export default function TodosTopPage() {
  const { obj: content_setting, updateObj: updateContentSetting } = useObj<ContentSetting>({
    contents: [],
  });
  const { obj: age_setting, updateObj: updateAgeSetting } = useObj<AgeSetting>({
    ages: all_ages,
  });
  const { obj: period_setting, updateObj: updatePeriodSetting } = useObj<PeriodSetting>({
    unit: 'hour',
    period: 'today',
  });

  const { cameras } = useCameras();
  const [map_cameras, setMapCameras] = useState<MapCamera[] | undefined>(undefined);
  const [searched_camera, setSearchedCamera] = useState<Camera | null>(null);
  const displaying_map_cameras = useMemo(() => {
    if (!searched_camera) return map_cameras;
    return map_cameras?.filter(isSearchedCamera(searched_camera));
  }, [map_cameras, searched_camera]);

  const loadTraffic = useCallback(
    (args: { display_content_setting?: ContentSetting; age_setting?: AgeSetting; period_setting?: PeriodSetting }) => {
      const { display_content_setting, age_setting, period_setting } = args;
      const use_human_traffic = display_content_setting?.contents.includes('humanTraffic');
      const use_gender = display_content_setting?.contents.includes('gender');
      const new_map_cameras = cameras?.map((camera) => {
        const result: MapCamera = { ...camera };
        // [TODO] ダミーデータをやめる
        let person_data = camera_person_data_record[camera.camera_id];
        if (period_setting) {
          person_data = generatePersonData();
        }
        if (use_human_traffic) {
          const partial_man: Partial<AgeGroups> = {};
          const partial_woman: Partial<AgeGroups> = {};
          age_setting?.ages.forEach((age) => {
            partial_man[age] = person_data.man[age];
            partial_woman[age] = person_data.woman[age];
          });
          const total = sumAgeGroups(partial_man) + sumAgeGroups(partial_woman);
          result.human_traffic = {
            total,
          };
        }
        if (use_gender) {
          const man_total = sumAgeGroups(person_data.man);
          const woman_total = sumAgeGroups(person_data.woman);
          result.gender = {
            men: man_total,
            women: woman_total,
          };
        }
        return result;
      });
      setMapCameras(new_map_cameras);
    },
    [cameras],
  );

  const customUpdatePeriodSetting = (form: PeriodSetting) => {
    updatePeriodSetting(form);
    loadTraffic({ display_content_setting: content_setting, age_setting, period_setting: form });
  };

  const customUpdateContentSetting = (form: ContentSetting) => {
    const { contents } = form;
    if (hasHumanTraffic(contents)) {
      updateAgeSetting({
        ages: all_ages,
      });
    }
    updateContentSetting(form);
    loadTraffic({ display_content_setting: form, age_setting });
  };

  const onAgeSettingModalFinish = (form: AgeSetting) => {
    updateAgeSetting(form);
    loadTraffic({ display_content_setting: content_setting, age_setting: form });
  };

  useEffect(() => {
    loadTraffic({});
  }, [loadTraffic]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <BaseMap
        map_cameras={displaying_map_cameras}
        detail_props={{
          period_setting: period_setting,
        }}
      />
      <div style={{ width: '100%', position: 'absolute' }}>
        <Space style={{ width: '100%', height: '100%' }} direction='vertical'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
            <Space size={8} style={{ zIndex: 2 }}>
              <SearchEngine setSearchedCamera={setSearchedCamera} searched_camera={searched_camera} />

              <PeriodSettingEngine
                form={period_setting}
                updateForm={customUpdatePeriodSetting}
                input_style={{ width: 250 }}
              />
              <ContentSettingEngine updateForm={customUpdateContentSetting} form={content_setting} />
              {hasHumanTraffic(content_setting.contents) && (
                <AgeSettingEngine updateForm={onAgeSettingModalFinish} form={age_setting} />
              )}
            </Space>
            <Space style={{ zIndex: 2 }}>
              <AccountSettingSetting />
            </Space>
          </div>
        </Space>
      </div>
    </div>
  );
}
