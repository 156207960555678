/*** エンティティ ***/

import { sendAxios } from '@shared/api/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601, toISO8601 } from '@shared/models/ISO8601';
import getClient from './base';

export interface Camera {
  camera_id: string;
  camera_name: string;
  camera_address: string;
  camera_description: string;
  thumnail_path: string;
  latitude: number;
  longitude: number;
  top: string;
  left: string;
  application_version: string;
  camera_type: string;
  app_param: string;
  is_open: boolean;
  status: 'active' | 'delete';
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCamera = (data: any): data is Camera => typeof data?.camera_id === 'string';
// 緯度経度のサンプル
const sample_latlng_list = [
  { latitude: 35.68615, longitude: 139.76645 },
  { latitude: 35.6418, longitude: 139.65135 },
  { latitude: 35.7012, longitude: 139.83255 },
  { latitude: 35.61635, longitude: 139.59095 },
  { latitude: 35.7449, longitude: 139.9268 },
  { latitude: 35.5867, longitude: 139.50275 },
  { latitude: 35.6695, longitude: 139.77185 },
  { latitude: 35.6615, longitude: 139.6723 },
  { latitude: 35.6721, longitude: 139.6969 },
  { latitude: 35.6646, longitude: 139.6942 },
  { latitude: 35.6624, longitude: 139.71655 },
  { latitude: 35.6599, longitude: 139.7032 },
  { latitude: 35.6663, longitude: 139.7091 },
  { latitude: 35.6572, longitude: 139.6887 },
  { latitude: 35.6629, longitude: 139.7017 },
  { latitude: 35.6557, longitude: 139.7189 },
  { latitude: 35.6634, longitude: 139.7074 },
  { latitude: 35.6571, longitude: 139.6892 },
  { latitude: 35.6626, longitude: 139.701 },
  { latitude: 35.6554, longitude: 139.7246 },
  { latitude: 35.666, longitude: 139.7109 },
  { latitude: 35.6548, longitude: 139.7076 },
  { latitude: 35.6613, longitude: 139.70915 },
  { latitude: 35.6556, longitude: 139.7203 },
  { latitude: 35.6652, longitude: 139.7078 },
  { latitude: 35.6587, longitude: 139.6971 },
  { latitude: 35.6638, longitude: 139.70575 },
  { latitude: 35.6563, longitude: 139.729 },
  { latitude: 35.6671, longitude: 139.7117 },
  { latitude: 35.6546, longitude: 139.7176 },
  { latitude: -6.239794, longitude: 106.798320 }, // Jakarta案件用に追加
  { latitude: -6.240692, longitude: 106.798854 },
  { latitude: -6.242315, longitude: 106.798613 },
  { latitude: -6.243060, longitude: 106.798623 },
  { latitude: -6.243751, longitude: 106.799081 },
  { latitude: -6.243808, longitude: 106.801366 },
  { latitude: -6.245424, longitude: 106.798047 }
];
// 施設座標のサンプル
const sample_top_left = [
  { top: '35', left: '59' },
  { top: '55', left: '29' },
  { top: '65', left: '39' },
  { top: '75', left: '49' },
  { top: '45', left: '69' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
  { top: '35', left: '139' },
];

const multipleCamera = (total: number): Camera[] => {
  const base_sample_camera: Camera = {
    camera_id: '00000000',
    camera_name: 'Camera 0',
    camera_address: '123 Main St, City',
    camera_description: 'This is the first camera.',
    latitude: 35.68055479159759,
    longitude: 139.767971314588,
    top: '50',
    left: '50',
    thumnail_path: 's3://thumbnails/camera1.jpg',
    application_version: '1.0.0',
    camera_type: 'pure',
    app_param: 'param1=value1&param2=value2',
    is_open: true,
    status: 'active',
    created_at: toISO8601(Date.now()),
    updated_at: toISO8601(Date.now()),
    deleted_at: null,
  };
  return new Array(total).fill(null).map((_, i) => {
    const no = i + 1;
    const camera_id = no.toString().padStart(8, '0');
    const camera_name = `カメラ${no}`;
    const camera_address = `東京都xxx区`;
    const latitude = sample_latlng_list[i % sample_latlng_list.length].latitude;
    const longitude = sample_latlng_list[i % sample_latlng_list.length].longitude;
    const top = sample_top_left[i % sample_top_left.length].top;
    const left = sample_top_left[i % sample_top_left.length].left;
    return {
      ...base_sample_camera,
      camera_id,
      camera_name,
      camera_address,
      latitude,
      longitude,
      top,
      left,
    };
  });
};

/*** [GET] /cameras ***/
export interface RequestCamerasGet extends CommonAPIRequestType {
  limit?: number;
}

export const camerasGetAPI = (args: RequestCamerasGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `cameras`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Camera[]>({
    axios,
    path,
    query,
    form,
    method,
    mock_response: multipleCamera(args.limit ?? 40),
  });
};
