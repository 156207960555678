export const charts_colors = {
  man: '#3498DB',
  woman: '#F85550',
  preschooler: '#FFCD55',
  child: '#02B5B2',
  youth: '#F56582',
  middleAged: '#50AD76',
  elderly: '#445771',
  linear_line: '#333333',
};

export type ChartsColorsKeys = keyof typeof charts_colors;
