import { Unit } from '@shared/models/Unit';
import { generatePersonData } from 'api/countData';

export const reloadSampleMetrics = (args: { unit: Unit; start: number; end: number }) => {
  const { start, end } = args;
  const start_unix = start;
  const end_unix = end;
  const mid_unix = ~~((start_unix + end_unix) / 2);
  return [generatePersonData(start_unix), generatePersonData(mid_unix), generatePersonData(end_unix)];
};
