import { SelectItem } from './SelectItem';

export type Gender = 'man' | 'woman';

export const all_genders: Gender[] = ['man', 'woman'];

export const hasAllGenders = (gender: Gender[]) => {
  const unique_generations = new Set(gender);
  return unique_generations.has('man') && unique_generations.has('woman');
};

export const genderToJP = (gender: Gender) => {
  switch (gender) {
    case 'man':
      return '男性';
    case 'woman':
      return '女性';
    default:
      return '';
  }
};

export const age_select_items: SelectItem<Gender>[] = [
  { value: 'man', label: genderToJP('man') },
  { value: 'woman', label: genderToJP('woman') },
];
