import { toContentSettingValue } from '@shared/functions';
import { Input, ModalProps, Popover, Space } from 'antd';
import { ContentSelect, ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { useMemo } from 'react';

export interface ContentSettingEngineProps extends ModalProps {
  updateForm(form: ContentSetting): void;
  form: ContentSetting;
}
export function ContentSettingEngine(props: ContentSettingEngineProps) {
  const { form, updateForm } = props;
  const content_setting_value = useMemo(() => {
    return toContentSettingValue(form.contents);
  }, [form]);
  return (
    <Popover
      content={
        <Space size={[0, 16]} direction='vertical' style={{ width: '100%' }}>
          <ContentSelect form={form} updateForm={updateForm} />
        </Space>
      }
      trigger='click'
    >
      <Input placeholder='クリックして値を選択' value={content_setting_value} readOnly style={{ width: 250 }} />
    </Popover>
  );
}
