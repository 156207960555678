import { Drawer, Input, List, Spin, Typography } from 'antd';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';
import history from '@shared/browserHistory';
import { Camera, isCamera } from 'api/cameras';
import Icon, { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { CameraMarkerSvg, FacilitySvg, MapSvg } from '@shared/components/Icons';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useMemo, useState } from 'react';
import { Map, isMap } from 'api/maps';
import './SearchEngine.css';

interface InputSuffixProps {
  searched: boolean;
  onClear(): void;
  onSearch(): void;
}
function InputSuffix({ searched, onClear, onSearch }: InputSuffixProps) {
  const style = { fontSize: 16, color: 'rgba(0, 0, 0, 0.45)' };
  return searched ? (
    <CloseOutlined onClick={onClear} style={style} />
  ) : (
    <SearchOutlined onClick={onSearch} style={style} />
  );
}

export interface SearchEngineProps {
  searched_camera: Camera | null;
  setSearchedCamera(camera: Camera | null): void;
}

export function SearchEngine({ searched_camera, setSearchedCamera }: SearchEngineProps) {
  const { cameras, loadCameras } = useCameras();
  const [value, setValue] = useState<string>('');
  const { bool, onOpen, onClose } = useBoolean();
  const { maps } = useMaps();

  const items: (Camera | Map)[] | undefined = useMemo(() => {
    if (!cameras && !maps) return undefined;
    const result: (Camera | Map)[] = [];
    maps?.forEach((map) => {
      result.push(map);
    });
    cameras?.forEach((camera) => {
      result.push(camera);
    });
    return result;
  }, [maps, cameras]);

  const onInputClear = () => {
    setValue('');
    setSearchedCamera(null);
  };

  const onSearch = () => {
    onOpen();
    loadCameras();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setValue(value);
    if (!value) {
      setSearchedCamera(null);
    }
  };

  const onClickCameraRow = (camera: Camera) => {
    history.push(`/urban/top?camera_id=${camera.camera_id}`);
    setSearchedCamera(camera);
    onClose();
  };

  const onClickFacilityRow = () => {
    history.push(`/facility/top`);
    // history.push(`/facility/top/map_id=${map.map_id}`);
  };
  return (
    <div>
      <Input
        placeholder='検索する'
        value={value}
        onChange={onInputChange}
        onPressEnter={onSearch}
        suffix={<InputSuffix searched={!!searched_camera} onClear={onInputClear} onSearch={onSearch} />}
        style={{ width: 200 }}
      />
      <Drawer placement='left' width={300} onClose={onClose} open={bool} closeIcon={null}>
        {items ? (
          <List
            itemLayout='horizontal'
            dataSource={items}
            renderItem={(item) => {
              let meta: {
                type: string;
                title: string;
                description: string;
                onClick?(): void;
              } = {
                type: '',
                title: '',
                description: '',
                onClick: undefined,
              };
              if (isCamera(item)) {
                meta = {
                  type: 'camera',
                  title: item.camera_name,
                  description: item.camera_address,
                  onClick: () => onClickCameraRow(item),
                };
              } else if (isMap(item)) {
                meta = {
                  type: item.is_facility ? 'facility' : 'map',
                  title: item.map_name,
                  description: item.map_description,
                  onClick: () => onClickFacilityRow(),
                };
              }

              return (
                <List.Item onClick={meta.onClick} className='antd-list-item'>
                  <List.Item.Meta
                    avatar={<Icon component={() => <DynamicSvg type={meta.type} />} />}
                    title={<Typography.Text>{meta.title}</Typography.Text>}
                    description={meta.description}
                  />
                </List.Item>
              );
            }}
          />
        ) : (
          <Spin />
        )}
      </Drawer>
    </div>
  );
}

function DynamicSvg(props: { type: string }) {
  const { type } = props;
  switch (type) {
    case 'map':
      return <MapSvg style={{ width: 48, height: 48 }} />;
    case 'camera':
      return <CameraMarkerSvg style={{ width: 48, height: 48 }} />;
    case 'facility':
      return <FacilitySvg style={{ width: 48, height: 48 }} />;
    default:
      undefined;
  }
}
