import { ContextMenuItemClickEvent, LatLng, LeafletEvent, LeafletEventHandlerFnMap } from 'leaflet';
import { MapContainer, ZoomControl, TileLayer, useMap, useMapEvents, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// いい感じのcontextmenuをmapに拡張するためのプラグイン
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';

// カスタムマーカーアイコンの画像ファイルをインポート;
import { Camera } from 'api/cameras';
import { Col, Row, Space, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { TopDetailModal, TopDetailModalProps } from './TopDetailModal';
import { useEffect, useState } from 'react';
import { CameraMarkerLeafletIcon, CircleLeafletIcon, PinLeafletIcon } from 'components/LeafletIcons';
import { CameraMarkerLeafletIconProps } from 'components/LeafletIcons/CameraMarkerLeafletIcon';

const getZoomParameter = (zoom_level?: number) => {
  return zoom_level ? (zoom_level * zoom_level - zoom_level) * 0.003 : 0;
};

/**
 *
 * @param zoom_level (leafletから取得できるzoom level 0 ~ 18)
 * @returns zoom levelに対する経度の移動量
 */
const getLongitudeShiftRelativeToZoomLevel = (zoom_level?: number) => {
  if (zoom_level === undefined || zoom_level < 0 || zoom_level > 18) return 0;
  return 0.00175 * Math.pow(2, 18 - zoom_level);
};

const getCircleSize = (num?: number, zoom_level?: number) => {
  if (typeof num !== 'number') return 0;
  const zoom_parameter = getZoomParameter(zoom_level);
  const max_num = 1000;
  const min_num = 10;
  const _num = Math.max(Math.min(num, max_num), min_num);
  const a = 0.35 * zoom_parameter;
  const b = 0;
  return a * _num + b;
};

export interface MapCamera extends Camera {
  human_traffic?: {
    total: number;
  };
  gender?: {
    men: number;
    women: number;
  };
}

interface LocationMakerProps {
  handlers?: Partial<LeafletEventHandlerFnMap>;
}
function LocationMarker({ handlers }: LocationMakerProps) {
  useMapEvents({
    ...handlers,
  });

  return null;
}

interface ChangeMapCenterProps {
  position: LatLng;
}
/**
 * 中心座標の変更をleafletに伝えるコンポーネント
 */
function ChangeMapCenter({ position }: ChangeMapCenterProps) {
  const map = useMap();

  useEffect(() => {
    map.panTo(new LatLng(position.lat, position.lng));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return null;
}

export interface BaseMapProps {
  map_cameras?: MapCamera[];

  detail_props: Pick<TopDetailModalProps, 'period_setting'>;
}
const sibuya_station = new LatLng(-6.242710, 106.798613);

export function BaseMap({ map_cameras, detail_props }: BaseMapProps) {
  const [center_position, setCenterPosition] = useState<LatLng>(sibuya_station);
  const [home_position, setHomePosition] = useState<LatLng>(sibuya_station);
  const [selected_camera, setSelectedCamera] = useState<Camera | null>(null);
  const [zoom_level, setZoomLevel] = useState<number>(17);

  function contextmenu(ev: ContextMenuItemClickEvent) {
    const latlng = ev.latlng;
    setHomePosition(latlng);
  }
  function zoomend(e: LeafletEvent) {
    setZoomLevel(e.target._zoom);
  }
  function onCustomCameraMarkerClick(camera: Camera) {
    const { latitude, longitude } = camera;
    // 選択されたカメラが詳細ダイアログに隠れないように、マップの経度を+にずらす
    const position = new LatLng(latitude, longitude + getLongitudeShiftRelativeToZoomLevel(zoom_level));
    setCenterPosition(position);
    setSelectedCamera(camera);
  }
  function onCustomModalClose() {
    setSelectedCamera(null);
  }

  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
      <MapContainer
        center={center_position}
        zoom={zoom_level}
        style={{ width: '100%', height: '100%', zIndex: 0, position: 'relative' }}
        zoomControl={false}
        contextmenu={true}
        contextmenuItems={[
          {
            text: 'ピン移動',
            callback: contextmenu,
          },
        ]}
      >
        <LocationMarker handlers={{ zoomend }} />
        <ChangeMapCenter position={center_position} />
        <PinLeafletIcon position={home_position} size={40}>
          <Popup>
            <Typography.Text>
              中心座標を表しています。
              <br />
              変更するにはマップ上で右クリック操作をしてください
            </Typography.Text>
          </Popup>
        </PinLeafletIcon>
        {selected_camera && (
          <TopDetailModal
            {...detail_props}
            open={!!selected_camera}
            camera={selected_camera}
            onCancel={onCustomModalClose}
          />
        )}
        {map_cameras?.map((map_camera) => {
          const { latitude, longitude, human_traffic, gender, camera_name } = map_camera;
          let gradient_option: CameraMarkerLeafletIconProps['gradient_option'] = undefined;
          const descriptions_items: { key: string; label: string }[] = [];
          if (human_traffic) {
            descriptions_items.push({
              key: 'human_traffic',
              label: `合計：${human_traffic.total}人`,
            });
          }
          if (gender) {
            const gender_total = gender.men + gender.women;
            const men_percent = (gender.men / gender_total) * 100;
            const women_percent = (gender.women / gender_total) * 100;
            descriptions_items.push({
              key: 'gender',
              label: `男性：${men_percent.toFixed(0)}% 女性：${women_percent.toFixed(0)}%`,
            });
            gradient_option = {
              left_percent: men_percent,
            };
          }
          const position = new LatLng(latitude, longitude);

          const circle_size = getCircleSize(human_traffic?.total, zoom_level);

          return (
            <Space key={map_camera.camera_id}>
              <CameraMarkerLeafletIcon
                selected={selected_camera?.camera_id === map_camera.camera_id}
                size={56}
                position={position}
                gradient_option={gradient_option}
                marker_props={{
                  zIndexOffset: 5,
                }}
                onClick={() => onCustomCameraMarkerClick(map_camera)}
                tooltip_content={
                  <Space direction='vertical'>
                    <Title level={5} style={{ margin: 0 }}>
                      {camera_name}
                    </Title>
                    <Row gutter={[16, 4]} style={{ width: 200 }}>
                      {descriptions_items.map((item) => {
                        return (
                          <Col key={item.key} span={24}>
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                  </Space>
                }
              />
              {typeof human_traffic?.total === 'number' && (
                <CircleLeafletIcon size={circle_size} opacity={0.7} position={position} color='orange' />
              )}
            </Space>
          );
        })}
        <ZoomControl position='bottomleft' />
        <TileLayer
          attribution='© <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
      </MapContainer>
    </div>
  );
}
