import history from '@shared/browserHistory';
import { useObj } from '@shared/hooks/useObj/useObj';
import { Button, Col, Input, Row, Typography } from 'antd';
import { authPostAPI } from 'api/users';
import { useSetAtom } from 'jotai';
import { user_atom } from 'customJotai/atom';

export function LoginPage() {
  const { obj: form, updateObj: updateForm } = useObj<{ login_id: string; password: string }>({
    login_id: '',
    password: '',
  });
  const setUser = useSetAtom(user_atom);
  const login = async () => {
    const { login_id, password } = form;
    const res = await authPostAPI({ login_id, password });
    if (res.status === 200) {
      setUser(res.data);
      history.push('/urban/top');
    }
  };

  const onLoginIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    updateForm({ login_id: value });
  };
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    updateForm({ password: value });
  };
  return (
    <Row
      justify='center'
      align='middle'
      style={{
        maxWidth: 300,
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translateY(-40%) translateX(-50%)',
      }}
      gutter={[16, 16]}
    >
      <Col span={24}>
        <Typography.Title>IDEA DemoGrid</Typography.Title>
      </Col>
      <Col span={24}>
        <Input value={form.login_id} placeholder='ユーザIDを入力' onChange={onLoginIdChange} />
      </Col>
      <Col span={24}>
        <Input.Password value={form.password} placeholder='パスワードを入力' onChange={onPasswordChange} />
      </Col>
      <Col span={24}>
        <Button onClick={login} type='primary' shape='round'>
          ログイン
        </Button>
      </Col>
    </Row>
  );
}
