import { useCallback, useEffect, useMemo, useState } from 'react';
import { PersonData } from 'api/countData';
import { Unit } from '@shared/models/Unit';
import { reloadSampleMetrics } from './sample';
import { Camera } from 'api/cameras';

/**
 * メトリック一覧を取得するcustom hooks
 */

export const useMetrics = ({
  unit,
  start,
  end,
  camera,
}: {
  unit: Unit;
  start: number;
  end: number;
  camera: Camera;
}) => {
  const [metrics, setMetrics] = useState<PersonData[] | undefined>(undefined);
  const loadMetrics = useCallback(async (args: { unit: Unit; start: number; end: number }) => {
    setMetrics(undefined);
    const metrics: PersonData[] = reloadSampleMetrics(args);
    setMetrics(metrics);
  }, []);

  useEffect(() => {
    loadMetrics({ unit, start, end });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit, start, end, camera]);

  return useMemo(() => {
    return {
      metrics,
      setMetrics,
      loadMetrics,
    };
  }, [metrics, setMetrics, loadMetrics]);
};
