import { SelectItem } from './SelectItem';

export type Age = 'preschooler' | 'child' | 'youth' | 'middleAged' | 'elderly';

export const all_ages: Age[] = ['preschooler', 'child', 'youth', 'middleAged', 'elderly'];

export const hasAllAges = (generations: Age[]) => {
  const unique_generations = new Set(generations);
  return (
    unique_generations.has('preschooler') &&
    unique_generations.has('child') &&
    unique_generations.has('youth') &&
    unique_generations.has('middleAged') &&
    unique_generations.has('elderly')
  );
};

export const ageToJP = (age: Age) => {
  switch (age) {
    case 'preschooler':
      return '未就学児';
    case 'child':
      return '子供';
    case 'youth':
      return '青年';
    case 'middleAged':
      return '中年';
    case 'elderly':
      return '高齢者';
    default:
      return '';
  }
};

export const age_select_items: SelectItem<Age>[] = [
  { value: 'preschooler', label: ageToJP('preschooler') },
  { value: 'child', label: ageToJP('child') },
  { value: 'youth', label: ageToJP('youth') },
  { value: 'middleAged', label: ageToJP('middleAged') },
  { value: 'elderly', label: ageToJP('elderly') },
];
