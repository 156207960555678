// カスタムマーカーアイコンの画像ファイルをインポート;
import { Camera } from 'api/cameras';
import { Map } from 'api/maps';
import { Space, UploadFile } from 'antd';
import Title from 'antd/es/typography/Title';
import { CameraMarkerIcon } from 'components/FacilityIcons';

export interface FacilityMapProps {
  map?: Map;
  map_cameras?: Camera[];
  facility_img?: UploadFile;
  selected_camera?: Camera;
}

export function FacilityMap({ map, map_cameras, selected_camera, facility_img }: FacilityMapProps) {
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'auto' }}>
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <img style={{ width: '100%', height: '100%' }} src={facility_img?.thumbUrl ?? map?.facility_image} />
        {map_cameras?.map((map_camera) => {
          const { camera_name } = map_camera;

          return (
            <Space key={map_camera.camera_id}>
              <CameraMarkerIcon
                selected={selected_camera?.camera_id === map_camera.camera_id}
                style={{
                  position: 'absolute',
                  top: map_camera.top + '%',
                  left: map_camera.left + '%',
                  cursor: 'pointer',
                  zIndex: 10,
                  width: 40,
                  height: 40,
                }}
                // クリックってかドラッグ
                // onClick={() => onCustomCameraMarkerClick(map_camera)}
                tooltip_content={
                  <Space direction='vertical'>
                    <Title level={5} style={{ margin: 0 }}>
                      {camera_name}
                    </Title>
                  </Space>
                }
              />
            </Space>
          );
        })}
      </div>
    </div>
  );
}
