import { Button, Col, Divider, Input, Row, Select, Space, Typography, Upload, message } from 'antd';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { ContentSettingEngine } from 'components/ContentSettingEngine';
import { useObj } from '@shared/hooks/useObj/useObj';
import { all_ages } from '@shared/models/Age';
import { FacilityMap } from 'pages/facility/top/EditPage/FacilityMap';
import { useCameras } from 'hooks/useCameras/useCameras';
import { useMaps } from 'hooks/useMaps/useMaps';
import { useEffect, useState } from 'react';
import { AccountSettingSetting } from 'pages/urban/top/TopPage/AccountSetting';
import { Map } from 'api/maps';
import { hasHumanTraffic } from '@shared/models/Content';
import { AgeSetting } from 'components/AgeSettingEngine/AgeSelect';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { AgeSettingEngine } from 'components/AgeSettingEngine';
import { ContentSetting } from 'components/ContentSettingEngine/ContentSelect';
import { SettingOutlined, GlobalOutlined, UploadOutlined } from '@ant-design/icons';
import history from '@shared/browserHistory';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload';
import { Camera } from 'api/cameras';

export default function TodosTopPage() {
  const { obj: content_setting, updateObj: updateContentSetting } = useObj<ContentSetting>({
    contents: [],
  });
  const { obj: age_setting, updateObj: updateAgeSetting } = useObj<AgeSetting>({
    ages: all_ages,
  });
  const { obj: period_setting, updateObj: updatePeriodSetting } = useObj<PeriodSetting>({
    unit: 'hour',
    period: 'today',
  });

  const { cameras, cameras_record } = useCameras(5);
  const { maps, maps_record } = useMaps();
  const [selected_map, setSelectedMap] = useState<Map | undefined>();
  const {
    maps: facility_maps,
    maps_record: facility_maps_record,
    loadMaps: loadFacilityMaps,
  } = useMaps(selected_map?.map_id);
  const [selected_facility_map, setSelectedFacilityMap] = useState<Map | undefined>();
  const [new_facility_name, setNewFacilityName] = useState<string>('');
  const [selected_camera, setSelectedCameras] = useState<Camera | undefined>(undefined);
  const [new_camera_name, setNewCameraName] = useState<string>('');
  // const [setImageUrl] = useState<string>();
  // const [loading, setLoading] = useState(false);
  const [selectFacilityOpen, setSelectFacilityOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const customUpdatePeriodSetting = (form: PeriodSetting) => {
    updatePeriodSetting(form);
  };

  const customUpdateContentSetting = (form: ContentSetting) => {
    const { contents } = form;
    if (hasHumanTraffic(contents)) {
      updateAgeSetting({
        ages: all_ages,
      });
    }
    updateContentSetting(form);
  };

  const onAgeSettingModalFinish = (form: AgeSetting) => {
    updateAgeSetting(form);
  };

  // マップの変更
  const onMapChange = (new_map_id: string) => {
    setSelectedMap(maps_record[new_map_id]);
    loadFacilityMaps(new_map_id);
    // loadAll(base_request);
  };

  // 施設の変更
  const onFacilityChange = (new_facility_map_id: string) => {
    setSelectedFacilityMap(facility_maps_record[new_facility_map_id]);
    // loadFacilityMaps(new_facility_map_id);
  };

  // 施設新規作成
  const onFacilityCreate = () => {
    setSelectedFacilityMap(undefined);
    setNewFacilityName('');
  };

  // 施設名変更
  const onFacilityNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFacilityName(e.currentTarget.value);
  };

  // カメラ変更
  const onCameraChange = (new_camera_id: string) => {
    setSelectedCameras(cameras_record[new_camera_id]);
    setNewCameraName(cameras_record[new_camera_id].camera_name);
  };

  // カメラ名変更
  const onCameraNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCameraName(e.currentTarget.value);
    // loadFacilityMaps(new_facility_map_id);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('アップロード画像はJPGかPNG形式のみになります。');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('アップロード画像は10MB以下になります。!');
    }
    return isJpgOrPng && isLt2M;
  };

  // const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result as string));
  //   reader.readAsDataURL(img);
  // };

  // const handleUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
  //   if (info.file.status === 'uploading') {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj as RcFile, (url) => {
  //       setLoading(false);
  //       setImageUrl(url);
  //     });
  //   }
  // };

  // const uploadButton = (
  //   <div>
  //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div style={{ marginTop: 8 }}>Upload</div>
  //   </div>
  // );

  // UrbanViewへの遷移
  const onUrbanview = () => {
    history.push(`/urban/top`);
  };

  // カメラ設定への遷移
  const onCameraEdit = () => {
    setStep(2);
  };

  // トップページへの遷移
  const onReturn = () => {
    history.push(`/facility/top`);
  };

  // 施設編集への遷移
  const onFacilityEdit = () => {
    setStep(1);
  };

  // 保存処理
  const onFinish = () => {
    history.push(`/facility/top`);
  };

  // 画像アップロード ※上手くいかない
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [thumbnail, setThumbnail] = useState<string>();
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    // setThumbnail(newFileList[0]?.thumbUrl);
  };

  useEffect(() => {
    setSelectedMap(maps?.[0]);
    // loadFacilityMaps(maps?.[0]?.map_id);
  }, [
    maps,
    // loadFacilityMaps
  ]);

  useEffect(() => {
    setSelectedFacilityMap(facility_maps?.[0]);
  }, [facility_maps]);

  useEffect(() => {
    setNewFacilityName(selected_facility_map?.facility_name ?? '');
  }, [selected_facility_map]);
  console.log(fileList[0]);
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: 60, backgroundColor: '#000' }}>
        <Space style={{ width: '100%', height: '100%' }} direction='vertical'>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
            <Space size={8} style={{ zIndex: 2 }}>
              <PeriodSettingEngine
                form={period_setting}
                updateForm={customUpdatePeriodSetting}
                input_style={{ width: 250 }}
              />
              <ContentSettingEngine updateForm={customUpdateContentSetting} form={content_setting} />
              {hasHumanTraffic(content_setting.contents) && (
                <AgeSettingEngine updateForm={onAgeSettingModalFinish} form={age_setting} />
              )}
            </Space>
            <Space style={{ zIndex: 2 }}>
              <Col span={24}>
                <Button onClick={onUrbanview} type='primary' shape='round'>
                  <GlobalOutlined /> UrbanView
                </Button>
              </Col>
              <AccountSettingSetting />
            </Space>
          </div>
        </Space>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 60px)' }}>
        <div style={{ width: '200px', borderRight: '1px solid #808080', padding: '10px', textAlign: 'left' }}>
          {step === 1 ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Text strong>施設</Typography.Text>
                <Select
                  style={{ width: '100%' }}
                  value={selected_map?.map_id ?? ''}
                  onChange={onMapChange}
                  options={maps?.map((map) => {
                    return { value: map.map_id, label: map.map_name };
                  })}
                />
              </Col>

              <Col span={24}>
                <Typography.Text strong>施設詳細</Typography.Text>
                <Select
                  style={{ width: '100%' }}
                  value={selected_facility_map?.map_id ?? '＋新規作成'}
                  open={selectFacilityOpen}
                  onChange={onFacilityChange}
                  onClick={() => setSelectFacilityOpen(!selectFacilityOpen)}
                  onDropdownVisibleChange={() => ''}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          padding: '4px 8px',
                          cursor: 'pointer',
                          color: '#1890ff',
                          width: '100%',
                        }}
                        onClick={onFacilityCreate}
                      >
                        ＋新規作成
                      </div>
                    </>
                  )}
                  options={facility_maps?.map((map) => {
                    return { value: map.map_id, label: map.facility_name };
                  })}
                ></Select>
              </Col>
              <Col span={24}>
                <Typography.Text strong>施設詳細名</Typography.Text>
                <Input placeholder='施設詳細名の入力' value={new_facility_name} onChange={onFacilityNameChange} />
              </Col>
              <Col span={24}>
                <Typography.Text strong>施設詳細画像</Typography.Text>
                <Upload
                  listType='picture'
                  beforeUpload={beforeUpload}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  onPreview={onPreview}
                  fileList={fileList}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
              <Col span={24}>
                <Button onClick={onCameraEdit} type='primary' shape='round'>
                  <SettingOutlined /> カメラ設定に移動
                </Button>
              </Col>
              <Col span={24}>
                <Button onClick={onReturn} type='primary' shape='round' style={{ backgroundColor: '#808080' }}>
                  <SettingOutlined /> 戻る
                </Button>
              </Col>
            </Row>
          ) : step === 2 ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Text strong>カメラ選択</Typography.Text>
                <Select
                  style={{ width: '100%' }}
                  value={selected_camera?.camera_id ?? ''}
                  onChange={onCameraChange}
                  options={cameras?.map((camera) => {
                    return { value: camera.camera_id, label: camera.camera_name };
                  })}
                />
              </Col>
              <Col span={24}>
                <Typography.Text strong>カメラ名</Typography.Text>
                <Input placeholder='カメラ名の入力' value={new_camera_name} onChange={onCameraNameChange} />
              </Col>
              <Col span={24}>
                <Button onClick={onFinish} type='primary' shape='round'>
                  <SettingOutlined /> 保存
                </Button>
              </Col>
              <Col span={24}>
                <Button onClick={onFacilityEdit} type='primary' shape='round' style={{ backgroundColor: '#808080' }}>
                  <SettingOutlined /> 施設編集に戻る
                </Button>
              </Col>
            </Row>
          ) : null}
        </div>
        {selected_facility_map && (
          <FacilityMap
            map={selected_facility_map}
            map_cameras={cameras}
            // facility_img={thumbnail}
            facility_img={fileList[0]}
            selected_camera={selected_camera}
          />
        )}
      </div>
    </div>
  );
}
