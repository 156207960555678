import { charts_colors, ChartsColorsKeys } from '@shared/components/Charts/colors';
import { TitleTaggedComponent, TitleTaggedComponentItems } from '@shared/components/TitleTaggedComponent';
import {
  generateHourUnixTimes,
  generateDayUnixTimes,
  generateWeekUnixTimes,
  generateMonthUnixTimes,
  formatWeekDate,
} from '@shared/functions/date';
import { Unit } from '@shared/models/Unit';
import { Select, Typography, Spin } from 'antd';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Label,
  YAxis,
  Legend,
  Line,
  PieChart,
  Pie,
  Cell,
  Tooltip as RechartsToolTip,
} from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import { PeriodSettingEngine } from 'components/PeriodSettingEngine';
import { useObj } from '@shared/hooks/useObj/useObj';
import { periodSettingToRange } from 'pages/urban/top/functions';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Camera } from 'api/cameras';
import { useTimeMetrics } from 'hooks/useMetrics/useTimeMetrics';
import { useKeyMetrics } from 'hooks/useMetrics/useKeyMetrics';
import { countDataGetRequest } from 'api/countData';
import { saveAs } from 'file-saver';
import { useCurrentPng } from 'recharts-to-png';
import { jsonToCsv } from '@shared/functions';
import { Formatter } from 'recharts/types/component/DefaultLegendContent';

const direction_options = [
  { value: 'LR', label: '駅へ向かう方向' },
  { value: 'RL', label: 'センター街へ向かう方向' },
];

const pieLegendFormatter: Formatter = (value, entry) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const percent = (entry.payload as any)?.percent;
  if (typeof percent !== 'number') {
    return value;
  }

  return `${value} ${(percent * 100).toFixed(0)}%`;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
      style={{ fontSize: 'small' }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const getTicks = (unit: Unit, start: Dayjs): (number | string)[] => {
  switch (unit) {
    case 'hour':
      return generateHourUnixTimes(start);
    case 'day':
      return generateDayUnixTimes(start);
    case 'week':
      return generateWeekUnixTimes(start);
    case 'month':
      return generateMonthUnixTimes(start);
    default:
      return [];
  }
};

const tickFormatter = (unit: Unit, start_date?: Dayjs) => (datapoint: number) => {
  const date = dayjs.unix(datapoint);
  let result = '';
  if (unit === 'hour') {
    result = date.format('mm');
    // 最後のデータだけ00を60に変更する
    if (result === '00' && start_date) {
      // 秒以下を切り捨て
      const start = dayjs(start_date).startOf('hour').unix();
      if (datapoint >= start + 60 * 60) {
        result = '60';
      }
    }
  } else if (unit === 'day') {
    result = date.format('HH');
    // 最後のデータだけ00を24に変更する
    if (result === '00' && start_date) {
      // 時以下を切り捨て
      const start = dayjs(start_date).startOf('day').unix();
      if (datapoint >= start + 60 * 60 * 24) {
        result = '24';
      }
    }
  } else if (unit === 'week') {
    result = formatWeekDate(date);
  } else if (unit === 'month') {
    result = date.format('DD');
  }
  return result;
};

const unitToXAxisLabel = (unit: Unit) => {
  switch (unit) {
    case 'hour':
      return '(分)';
    case 'day':
      return '(時)';
    case 'week':
      return undefined;
    case 'month':
      return '(日)';
    default:
      return undefined;
  }
};

export interface UrbanInfoRef {
  downloadCsv(): void;
  downloadPng(): void;
  isLoading(): boolean;
}

type DataType = 'count' | 'gender' | 'age';

export interface UrbanInfoProps {
  period_setting: PeriodSetting;
  camera: Camera;
}
export const UrbanInfo = forwardRef<UrbanInfoRef, UrbanInfoProps>(({ period_setting, camera }, ref) => {
  const { obj: period_setting_form, updateObj: updatePeriodSettingForm } = useObj<PeriodSetting>({
    ...period_setting,
  });
  const [direction, setDirection] = useState<string>('LR');
  const { metrics: count_metrics, loadMetrics: loadCountMetrics } = useTimeMetrics({
    data_type: 'total',
    unit: period_setting.unit,
  });
  const { metrics: gender_metrics, loadMetrics: loadSexMetrics } = useKeyMetrics({
    data_type: 'gender',
    unit: period_setting.unit,
  });
  const { metrics: age_metrics, loadMetrics: loadAgeMetrics } = useKeyMetrics({
    data_type: 'age',
    unit: period_setting.unit,
  });
  const [getCountPng, { ref: count_ref, isLoading: count_is_loading }] = useCurrentPng();
  const [getSexPng, { ref: gender_ref, isLoading: gender_is_loading }] = useCurrentPng();
  const [getAgePng, { ref: age_ref, isLoading: age_is_loading }] = useCurrentPng();

  const start_date = typeof count_metrics?.[0].time === 'number' ? dayjs.unix(count_metrics[0].time) : undefined;

  const ticks = start_date ? getTicks(period_setting_form.unit, start_date) : [];

  const onDirectionChange = (new_direction: string) => {
    setDirection(new_direction);
    const { start, end } = periodSettingToRange(period_setting);
    const base_request = { camera_id: camera.camera_id, start, end, unit: period_setting.unit, direction };
    loadAll(base_request);
  };
  const onPeriodSettingChange = (period_setting: PeriodSetting) => {
    updatePeriodSettingForm(period_setting);
    const { start, end } = periodSettingToRange(period_setting);
    const base_request = { camera_id: camera.camera_id, start, end, unit: period_setting.unit, direction };
    loadAll(base_request);
  };
  const loadAll = (request: Omit<countDataGetRequest, 'data_type'>) => {
    loadCountMetrics({
      ...request,
      data_type: 'total',
    });
    loadSexMetrics({
      ...request,
      data_type: 'gender',
    });
    loadAgeMetrics({
      ...request,
      data_type: 'age',
    });
  };

  const downloadCSV = () => {
    if (!count_metrics) return undefined;
    const csv = jsonToCsv(count_metrics);
    // BOMの設定
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'count.csv');
  };

  const downloadAllPng = async () => {
    await Promise.all(
      ['count', 'gender', 'age'].map((type) => {
        return downloadPng(type as DataType);
      }),
    );
  };

  const downloadPng = async (type: DataType) => {
    let png: string | undefined = undefined;
    let file_name = '';
    if (type === 'count') {
      png = await getCountPng();
      file_name = 'count_graph.png';
    } else if (type === 'gender') {
      png = await getSexPng();
      file_name = 'gender_graph.png';
    } else if (type === 'age') {
      png = await getAgePng();
      file_name = 'age_graph.png';
    }
    if (!png) return;
    saveAs(png, file_name);
  };

  useImperativeHandle(ref, () => ({
    downloadCsv() {
      downloadCSV();
    },
    downloadPng() {
      downloadAllPng();
    },
    isLoading() {
      return count_is_loading || gender_is_loading || age_is_loading;
    },
  }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ height: 76 }}>
        <TitleTaggedComponentItems
          items={[
            {
              title: '表示期間',
              children: <PeriodSettingEngine form={period_setting_form} updateForm={onPeriodSettingChange} />,
            },
            {
              title: '方向',
              children: (
                <Select
                  style={{ width: '100%' }}
                  value={direction}
                  onChange={onDirectionChange}
                  options={direction_options}
                />
              ),
            },
          ]}
        />
      </div>
      <div style={{ height: 'calc((100% - 76px) * 0.36)' }}>
        <TitleTaggedComponent title='通行者数' direction='vertical'>
          <ResponsiveContainer>
            {count_metrics ? (
              <LineChart data={count_metrics} margin={{ right: 15, bottom: 15 }} ref={count_ref}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='time'
                  type='number'
                  domain={['dataMin', 'dataMax']}
                  ticks={ticks}
                  tickFormatter={tickFormatter(period_setting_form.unit, start_date)}
                >
                  <Label value={unitToXAxisLabel(period_setting_form.unit)} offset={-10} position='insideBottomRight' />
                </XAxis>
                <YAxis>
                  <Label value='(人)' offset={0} position='insideTopLeft' />
                </YAxis>
                <RechartsToolTip
                  labelFormatter={(label: number) => {
                    const date = dayjs.unix(label);
                    return <Typography.Text>{date.format('YYYY/MM/DD HH:mm')}</Typography.Text>;
                  }}
                />
                <Line type='linear' dataKey='value' stroke={charts_colors.linear_line} />
              </LineChart>
            ) : (
              <Spin />
            )}
          </ResponsiveContainer>
        </TitleTaggedComponent>
      </div>
      <div style={{ height: 'calc((100% - 76px) * 0.33)' }}>
        <TitleTaggedComponent title='性別割合' direction='vertical'>
          <ResponsiveContainer>
            <PieChart ref={gender_ref}>
              <Pie
                data={gender_metrics}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                dataKey='value'
                startAngle={90}
                endAngle={450}
              >
                {gender_metrics?.map((gender_metric, index) => (
                  <Cell key={`cell-${index}`} fill={charts_colors[gender_metric.key as ChartsColorsKeys]} />
                ))}
              </Pie>
              <Legend align='right' verticalAlign='middle' layout='vertical' formatter={pieLegendFormatter} />
            </PieChart>
          </ResponsiveContainer>
        </TitleTaggedComponent>
      </div>
      <div style={{ height: 'calc((100% - 76px) * 0.33)' }}>
        <TitleTaggedComponent title='年代割合' direction='vertical'>
          <ResponsiveContainer>
            <PieChart ref={age_ref}>
              <Pie
                data={age_metrics}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                dataKey='value'
                startAngle={90}
                endAngle={-270}
              >
                {age_metrics?.map((age_metric, index) => {
                  const fill = charts_colors[age_metric.key as ChartsColorsKeys];
                  return <Cell key={`cell-${index}`} fill={fill} />;
                })}
              </Pie>
              <Legend align='right' verticalAlign='middle' layout='vertical' formatter={pieLegendFormatter} />
            </PieChart>
          </ResponsiveContainer>
        </TitleTaggedComponent>
      </div>
    </div>
  );
});
