import { getHourDateRange } from '@shared/functions/date';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import dayjs from 'dayjs';

export const periodSettingToRange = (period_setting: PeriodSetting) => {
  const { date, period, unit, times } = period_setting;
  let start = 0;
  let end = 0;
  if (period === 'today') {
    const today = dayjs();
    start = dayjs(today).startOf(unit).unix();
    end = dayjs(today).endOf(unit).unix();
  } else if (period === 'period') {
    if (!date) return { start, end };
    if (unit === 'hour') {
      const { start: sdate, end: edate } = getHourDateRange(date, times?.[0], times?.[1]);
      start = sdate.unix();
      end = edate.unix();
    } else {
      start = dayjs(date).startOf(unit).unix();
      end = dayjs(date).endOf(unit).unix();
    }
  }
  return { start, end };
};
