// カスタムマーカーアイコンの画像ファイルをインポート;
import { Camera } from 'api/cameras';
import { Map } from 'api/maps';
import { Col, Row, Space } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import Title from 'antd/es/typography/Title';
import { TopDetailModal, TopDetailModalProps } from './TopDetailModal';
import { useState } from 'react';
import { CameraMarkerIcon, CircleIcon } from 'components/FacilityIcons';
import { CameraMarkerIconProps } from 'components/FacilityIcons/CameraMarkerIcon';

const getCircleSize = (num?: number) => {
  if (typeof num !== 'number') return 0;
  const max_num = 1000;
  const min_num = 10;
  const _num = Math.max(Math.min(num, max_num), min_num);
  const a = 0.35 * 0.5;
  const b = 0;
  return a * _num + b;
};

export interface MapCamera extends Camera {
  human_traffic?: {
    total: number;
  };
  gender?: {
    men: number;
    women: number;
  };
}

export interface FacilityMapProps {
  map: Map;
  map_cameras?: MapCamera[];
  detail_props: Pick<TopDetailModalProps, 'period_setting'>;
}

export function FacilityMap({ map, map_cameras, detail_props }: FacilityMapProps) {
  const [selected_camera, setSelectedCamera] = useState<Camera | null>(null);

  function onCustomCameraMarkerClick(camera: Camera) {
    setSelectedCamera(camera);
  }
  function onCustomModalClose() {
    setSelectedCamera(null);
  }

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'auto' }}>
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <img style={{ width: '100%', height: 'auto' }} src={map.facility_image} />
        {selected_camera && (
          <TopDetailModal
            {...detail_props}
            open={!!selected_camera}
            style={{ left: 30, top: 90 }}
            camera={selected_camera}
            onCancel={onCustomModalClose}
          />
        )}
        {map_cameras?.map((map_camera) => {
          const { human_traffic, gender, camera_name } = map_camera;
          let gradient_option: CameraMarkerIconProps['gradient_option'] = undefined;
          const descriptions_items: DescriptionsItemType[] = [];
          if (human_traffic) {
            descriptions_items.push({
              key: 'human_traffic',
              label: `合計：${human_traffic.total}人`,
              children: <p>{human_traffic.total}</p>,
            });
          }
          if (gender) {
            const gender_total = gender.men + gender.women;
            const men_percent = (gender.men / gender_total) * 100;
            const women_percent = (gender.women / gender_total) * 100;
            descriptions_items.push({
              key: 'gender',
              label: `男性：${men_percent}% 女性：${women_percent}%`,
              children: <p>{men_percent}%</p>,
            });
            gradient_option = {
              left_percent: men_percent,
            };
          }

          const circle_size = getCircleSize(human_traffic?.total);

          return (
            <Space key={map_camera.camera_id}>
              <CameraMarkerIcon
                selected={selected_camera?.camera_id === map_camera.camera_id}
                style={{
                  position: 'absolute',
                  top: map_camera.top + '%',
                  left: map_camera.left + '%',
                  cursor: 'pointer',
                  zIndex: 10,
                  width: 40,
                  height: 40,
                }}
                gradient_option={gradient_option}
                onClick={() => onCustomCameraMarkerClick(map_camera)}
                tooltip_content={
                  <Space direction='vertical'>
                    <Title level={5} style={{ margin: 0 }}>
                      {camera_name}
                    </Title>
                    <Row gutter={[16, 4]} style={{ color: 'black', width: 200 }}>
                      {descriptions_items.map((item) => {
                        return (
                          <Col key={item.key} span={24}>
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                  </Space>
                }
              />
              {typeof human_traffic?.total === 'number' && (
                <CircleIcon
                  style={{
                    position: 'absolute',
                    top: `calc(${map_camera.top}% - ${circle_size}px / 2 + 20px)`,
                    left: `calc(${map_camera.left}% - ${circle_size}px / 2 + 20px)`,
                  }}
                  size={circle_size}
                  opacity={0.7}
                  color='orange'
                />
              )}
            </Space>
          );
        })}
      </div>
    </div>
  );
}
