/*** エンティティ ***/

import { sendAxios } from '@shared/api/sendAxios';
import { CommonAPIRequestType } from '@shared/models/CommonAPIType';
import { Query } from '@shared/models/Query';
import { ISO8601, toISO8601 } from '@shared/models/ISO8601';
import getClient from './base';
import { Status } from '@shared/models/Status';

export interface Map {
  map_id: string;
  map_name: string;
  map_description: string;
  is_open: boolean;
  is_facility: boolean;
  parent_map_id: string | null;
  facility_name: string;
  facility_image: string;
  status: Status;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMap = (data: any): data is Map => typeof data?.map_id === 'string';

const sample_map1: Map = {
  map_id: '00000001',
  map_name: '西武渋谷店',
  map_description: '西武渋谷店',
  is_open: true,
  is_facility: false,
  parent_map_id: null,
  facility_name: '渋谷施設1',
  facility_image: '',
  status: 'active',
  created_at: toISO8601(Date.now()),
  updated_at: toISO8601(Date.now()),
  deleted_at: null,
};

const sample_map2: Map = {
  map_id: '00000002',
  map_name: '渋谷区',
  map_description: '渋谷区100台プロジェクト用',
  is_open: false,
  is_facility: true,
  parent_map_id: null,
  facility_name: '',
  facility_image: '',
  status: 'active',
  created_at: toISO8601(Date.now()), // Sample UNIX timestamp
  updated_at: toISO8601(Date.now()),
  deleted_at: null,
};

const sample_map3: Map = {
  map_id: '00000003',
  map_name: '西武渋谷店',
  map_description: '西武渋谷店',
  is_open: false,
  is_facility: true,
  parent_map_id: '00000001',
  facility_name: '西武渋谷店1F',
  facility_image: 'https://www.sogo-seibu.jp/common/img/floor_guide/floor_guide_shibuya_01f.jpg',
  status: 'active',
  created_at: toISO8601(Date.now()), // Sample UNIX timestamp
  updated_at: toISO8601(Date.now()),
  deleted_at: null,
};

const sample_map4: Map = {
  map_id: '00000004',
  map_name: '西武渋谷店',
  map_description: '西武渋谷店',
  is_open: false,
  is_facility: true,
  parent_map_id: '00000001',
  facility_name: '西武渋谷店2F',
  facility_image: 'https://www.sogo-seibu.jp/common/img/floor_guide/floor_guide_shibuya_02f.jpg',
  status: 'active',
  created_at: toISO8601(Date.now()), // Sample UNIX timestamp
  updated_at: toISO8601(Date.now()),
  deleted_at: null,
};

function getSampleMap(parent_id?: string) {
  if (typeof parent_id === 'string') {
    return [sample_map3, sample_map4];
  }
  return [sample_map1, sample_map2];
}

/*** [GET] /maps ***/
export interface RequestMapsGet extends CommonAPIRequestType {
  parent_id?: string;
}

export const mapsGetAPI = (args: RequestMapsGet) => {
  // const {} = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `maps`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { parent_id: args.parent_id };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Map[]>({
    axios,
    path,
    query,
    form,
    method,
    mock_response: getSampleMap(args.parent_id),
  });
};
