import { useCallback, useEffect, useMemo, useState } from 'react';
import { TimeMetric, countDataGetRequest } from 'api/countData';

const data_week = [
  {
    value: 6,
    time: 1692457222,
  },
  {
    value: 17,
    time: 1692543666,
  },
  {
    value: 19,
    time: 1692889255,
  },
];

const data_hour = [
  {
    value: 30,
    time: 1692939623,
  },
  {
    value: 50,
    time: 1692940223,
  },
  {
    value: 75,
    time: 1692940823,
  },
];

const data_day = [
  {
    value: 6,
    time: 1692922823,
  },
  {
    value: 17,
    time: 1692948023,
  },
  {
    value: 19,
    time: 1692958823,
  },
];

const data_month = [
  {
    value: 77,
    time: 1691680823,
  },
  {
    value: 66,
    time: 1692410363,
  },
  {
    value: 55,
    time: 1693447163,
  },
];

/**
 * メトリック一覧を取得するcustom hooks
 */

export const useTimeMetrics = (default_request?: Partial<countDataGetRequest>) => {
  const [metrics, setMetrics] = useState<TimeMetric[] | undefined>(undefined);
  const loadMetrics = useCallback(async (args: countDataGetRequest) => {
    setMetrics(undefined);
    const { unit } = args;
    let new_metrics: TimeMetric[] = [];
    if (unit === 'hour') {
      new_metrics = data_hour;
    } else if (unit === 'day') {
      new_metrics = data_day;
    } else if (unit === 'week') {
      new_metrics = data_week;
    } else if (unit === 'month') {
      new_metrics = data_month;
    }
    setMetrics(new_metrics);
  }, []);

  useEffect(() => {
    loadMetrics({
      camera_id: default_request?.camera_id ?? '0000001',
      start: default_request?.start ?? 1693097842,
      end: default_request?.end ?? 1693184242,
      unit: default_request?.unit ?? 'hour',
      direction: default_request?.direction ?? 'LR',
      data_type: default_request?.data_type ?? 'total',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMetrics]);

  return useMemo(() => {
    return {
      metrics,
      setMetrics,
      loadMetrics,
    };
  }, [metrics, setMetrics, loadMetrics]);
};
