import { Modal, ModalProps, Space, Divider, Button, Typography } from 'antd';
import { UrbanInfo, UrbanInfoRef } from './UrbanInfo';
import { PeriodSetting } from 'components/PeriodSettingEngine/PeriodSelect';
import { Camera } from 'api/cameras';
import Icon from '@ant-design/icons';
import { MaximizeSvg } from '@shared/components/Icons/MaximizeSvg';
import { MinimizeSvg } from '@shared/components/Icons/MinimizeSvg';
import './TopDetailModal.css';

import React, { useMemo, useRef } from 'react';
import { useBoolean } from '@shared/hooks/useBoolean/useBoolean';

const getResizeIcon = (is_maximum: boolean) => {
  return is_maximum ? (
    <MinimizeSvg style={{ width: 16, height: 16, color: 'rgba(0, 0, 0, 0.45)' }} />
  ) : (
    <MaximizeSvg style={{ width: 16, height: 16, color: 'rgba(0, 0, 0, 0.45)' }} />
  );
};

export interface TopDetailModalProps extends ModalProps {
  camera: Camera;
  period_setting: PeriodSetting;
}
export function TopDetailModal(props: TopDetailModalProps) {
  const { camera, period_setting } = props;
  const { bool: is_large, onReverse } = useBoolean();
  const width = useMemo(() => {
    let result: string = '700px';
    if (is_large) {
      result = 'calc(100vw - 10px * 2)';
    }
    return result;
  }, [is_large]);

  const height = useMemo(() => {
    let result: string = '680px';
    if (is_large) {
      result = 'calc(100vh - 10px * 2)';
    }
    return result;
  }, [is_large]);
  // cssにheightを伝える
  document.documentElement.style.setProperty('--height', height);

  const urban_info_items = [
    {
      id: '1',
      default_direction: 'LR',
      ref: useRef<UrbanInfoRef>(null),
    },
    {
      id: '2',
      default_direction: 'RL',
      ref: useRef<UrbanInfoRef>(null),
    },
  ];

  const downloadCsv = () => {
    urban_info_items.forEach((uii) => {
      console.log(uii.ref.current);
      uii.ref.current?.downloadCsv();
    });
  };

  const downloadPng = () => {
    urban_info_items.forEach((uii) => {
      console.log(uii.ref.current);
      uii.ref.current?.downloadPng();
    });
  };

  const isDownloadLoading = () => {
    let is_loading = false;
    urban_info_items.forEach((uii) => {
      is_loading = is_loading && !!uii.ref.current?.isLoading();
    });
    return is_loading;
  };

  return (
    <Modal
      {...props}
      title={
        <>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {camera.camera_name}
          </Typography.Title>
          <Divider style={{ margin: '2px 0px 6px 0px' }} />
        </>
      }
      maskClosable={false}
      footer={null}
      width={width}
      centered={false}
      mask={false}
      style={{
        position: 'fixed',
        top: 10,
        right: is_large ? undefined : 10,
        left: is_large ? 10 : undefined,
      }}
    >
      <Button
        style={{
          position: 'absolute',
          top: 17,
          right: 47,
        }}
        className='ant-modal-close'
      >
        <span className='ant-modal-close-x' onClick={onReverse}>
          <Icon
            component={() => getResizeIcon(is_large)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </span>
      </Button>

      <div style={{ display: 'flex', height: 'calc(100% - 1px - 5px * 2 - 32px)', width: '100%' }}>
        {urban_info_items.map((urban_info_item, i) => {
          return (
            <React.Fragment key={i}>
              <div style={{ height: '100%', width: 'calc(50% - 5px)' }}>
                <UrbanInfo
                  period_setting={period_setting}
                  camera={camera}
                  default_direction={urban_info_item.default_direction}
                  ref={urban_info_item.ref}
                />
              </div>

              {i % 2 === 0 && <Divider type='vertical' style={{ height: '100%', fontWeight: 'bold' }} />}
            </React.Fragment>
          );
        })}
      </div>
      <Divider style={{ margin: '5px 0px' }} />
      <Space wrap style={{ height: 32 }}>
        <Button type='primary' shape='round' style={{ width: 180 }} onClick={downloadCsv} loading={isDownloadLoading()}>
          csvとしてデータ保存
        </Button>
        <Button type='primary' shape='round' style={{ width: 180 }} onClick={downloadPng}>
          画像としてデータ保存
        </Button>
      </Space>
    </Modal>
  );
}
