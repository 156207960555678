import { useCallback, useEffect, useMemo, useState } from 'react';
import { KeyMetric, countDataGetRequest } from 'api/countData';
import { ageToJP } from '@shared/models/Age';

const gender_data = [
  { key: 'man', name: '男性', value: 300 },
  { key: 'woman', name: '女性', value: 500 },
];

const gender_data2 = [
  { key: 'man', name: '男性', value: 100 },
  { key: 'woman', name: '女性', value: 50 },
];

const generation_data = [
  { key: 'preschooler', name: ageToJP('preschooler'), value: 300 },
  { key: 'child', name: ageToJP('child'), value: 200 },
  { key: 'youth', name: ageToJP('youth'), value: 500 },
  { key: 'middleAged', name: ageToJP('middleAged'), value: 800 },
  { key: 'elderly', name: ageToJP('elderly'), value: 600 },
];

const generation_data2 = [
  { key: 'preschooler', name: ageToJP('preschooler'), value: 40 },
  { key: 'child', name: ageToJP('child'), value: 10 },
  { key: 'youth', name: ageToJP('youth'), value: 40 },
  { key: 'middleAged', name: ageToJP('middleAged'), value: 5 },
  { key: 'elderly', name: ageToJP('elderly'), value: 5 },
];

/**
 * メトリック一覧を取得するcustom hooks
 */

export const useKeyMetrics = (default_request?: Partial<countDataGetRequest>) => {
  const [metrics, setMetrics] = useState<KeyMetric[] | undefined>(undefined);
  const loadMetrics = useCallback(async (args: countDataGetRequest) => {
    setMetrics(undefined);
    const { data_type, unit } = args;
    let new_metrics: KeyMetric[] = [];
    if (data_type === 'gender') {
      if (unit === 'hour' || unit === 'week') {
        new_metrics = gender_data;
      } else {
        new_metrics = gender_data2;
      }
    } else if (data_type === 'age') {
      if (unit === 'hour' || unit === 'week') {
        new_metrics = generation_data;
      } else {
        new_metrics = generation_data2;
      }
    }
    setMetrics(new_metrics);
  }, []);

  useEffect(() => {
    loadMetrics({
      camera_id: default_request?.camera_id ?? '0000001',
      start: default_request?.start ?? 1693097842,
      end: default_request?.end ?? 1693184242,
      unit: default_request?.unit ?? 'hour',
      direction: default_request?.direction ?? 'LR',
      data_type: default_request?.data_type ?? 'gender',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMetrics]);

  return useMemo(() => {
    return {
      metrics,
      setMetrics,
      loadMetrics,
    };
  }, [metrics, setMetrics, loadMetrics]);
};
